export default {
  components: {
    Chat: {
      FollowingAutocomplete: {
        label_destinatario: 'Recipient',
        message_no_user_found:
            'No user found. Remember: you can only chat with people you follow',
      },
      NewMessage: {
        titles: {
          newMessage: 'New Message',
          write: 'Write...',
        },
        buttons: {
          cancel: 'Cancel',
          send: 'Send',
          attachFiles: 'Attach files',
        },
        labels: {
          writeMessage: 'Write a message',
        },
        toast: {
          sendMessageError: 'Unable to send message: {error}',
          sendMessageUnexpectedError:
              'Unable to send message: Unexpected error. Please try again later.',
        },
      },
    },
    Comment: {
      Mention: {
        MentionList: {
          no_results: 'No Results',
        },
      },
      CommentEditor: {
        title_new_message: 'New Message',
        title_write: 'Write...',
        button_cancel: 'Cancel',
        button_send: 'Send',
        button_save: 'Save',
        button_attach_files: 'Attach files',
        placeholder_write_message: 'Write a message',
        placeholder_write_comment: 'Write a comment…',
        placeholder_write_reply: 'Write a reply…',
        toast_send_error: 'Unable to send message: ',
        toast_send_unexpected_error:
            'Unable to send message: Unexpected error. Please try again later.',
        emojiPickerLanguage: {
          search: 'Search...',
          categories: {
            search: 'Search Results',
            recent: 'Recent',
            smileys: 'Smileys & Emotions',
            people: 'People & Body',
            nature: 'Animals & Nature',
            foods: 'Food & Drinks',
            activity: 'Activities',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            custom: 'Custom',
          },
        },
      },
      CommentItem: {
        no_results: 'No Results',
        like: 'Like',
        unlike: 'Unlike',
        button_edit: 'Edit',
        button_cancel_edit: 'Cancel Edit',
        button_delete: 'Delete',
        button_report: 'Report',
        button_reply: 'Reply',
        button_cancel_reply: 'Cancel Reply',
        alert_delete_header: 'Delete',
        alert_delete_message: 'Do you really want to delete this comment?',
        alert_delete_cancel: 'Cancel',
        alert_delete_confirm: 'Delete',
      },
      ModalComment: {
        followButton: 'Follow',
        segment: {
          comments: 'Comments',
          likes: 'Likes',
        },
      },
    },
    Groups: {
      GroupCreate: {
        title_new_group: 'New Group',
        label_title: 'Title',
        label_description_optional: 'Description (Optional)',
        label_privacy: 'Privacy',
        option_public_group: 'Public Group',
        option_private_group: 'Private Group',
        button_delete: 'Delete',
        button_cancel: 'Cancel',
        button_update: 'Update',
        button_publish: 'Publish',
        delete_confirmation_header: 'Delete Group',
        delete_confirmation_message:
            'You are about to delete this group: {name}. Do you really want to proceed?',
      },
      GroupImageUpload: {
        update: 'Update',
        avatar: 'avatar',
        cover: 'cover',
        dragHere: 'Drag an image here',
        selectImage: 'Select an image',
        save: 'Save',
        cancel: 'Cancel',
        toast: {
          invalidImage: 'You have not selected an image',
        },
      },
      GroupItem: {
        subscribe: 'Subscribe',
        subscribeToGroup: 'Subscribe to this group',
        requestMembership: 'Request Membership',
        requestSent: 'Request Sent',
        cancel: 'Cancel',
        subscribed: 'Subscribed',
        public: 'Public',
        private: 'Private',
        members: 'members',
      },
      GroupItemAdminSettings: {
        followers: 'Followers',
        following: 'Following',
        buttons: {
          banUser: 'Ban User',
        },
        permissions: 'Permissions',
        roles: {
          admin: 'Admin',
          moderator: 'Moderator',
          user: 'User',
        },
      },
      GroupList: {
        noGroups: {
          part1: 'You are not subscribed to any group. Click on',
          explore: 'EXPLORE',
          part2: 'to find new groups.',
        },
        loadingText: 'Loading...',
      },
      GroupsHome: {
        myGroups: {
          part1: 'My',
          part2: 'Groups',
        },
        manage: 'Manage',
        explore: 'Explore',
        subscribedGroups: 'Groups you are subscribed to',
        subscribedGroupsWithName: 'Groups subscribed by {name}',
        createdAndManagedGroups: 'Groups created and managed by you',
        newGroup: 'New Group',
        exploreDantebusGroups: 'Explore groups on Dantebus',
      },
      GroupUserItem: {
        followers: 'Followers',
        following: 'Following',
        accept: 'Accept',
        removeBan: 'Remove Ban',
        administrator: 'Administrator',
        moderator: 'Moderator',
        subscribed: 'Subscriber',
      },
      GroupUsersList: {
        loadingText: 'Loading...',
      },
      ToggleSubscriptionButton: {
        leaveGroup: 'Leave the group',
        requestSent: 'Request sent',
        cancel: 'Cancel',
        subscribe: 'Subscribe',
        subscribed: 'Subscribed',
      },
    },
    MediaItem: {
      FullScreenImage: {
        close: 'Close',
      },
    },
    Notification: {
      NotificationList: {
        noNotifications: 'There are no notifications',
        loadingText: 'Loading...',
      },
      NotificationPopover: {
        notifications: 'Notifications',
        markAsRead: 'Mark as read',
      },
    },
    PageError: {
      NotFound: {
        message:
            "We're sorry, you can't view this post. You probably don't have the required permissions or it has been deleted.",
      },
    },
    Popovers: {
      AddMediaItem: {
        uploadFile: 'Upload a file',
        writeText: 'Write text',
        selectFromLibrary: 'Select from library',
      },
      ChatOptions: {
        profile: 'Profile',
        unblockUser: 'Unblock User',
        blockUser: 'Block User',
        deleteConversation: 'Delete Conversation',
        loading: 'Loading...',
      },
      ChatSingleMessageOptions: {
        deleteMessage: 'Delete message',
      },
      PostItemMenu: {
        report: 'Report',
        edit: 'Edit',
        delete: 'Delete',
        loading: 'Loading...',
        deletePostHeader: 'Delete Post',
        deletePostMessage:
            'You are about to delete this post. Do you really want to proceed?',
        cancel: 'Cancel',
        post: 'Post',
      },
      RequestPermission: {
        title: 'Push Notifications',
        message:
            "Allow push notifications on your device.\nDon't miss any notifications",
        deny: 'Later',
        grant: 'Allow',
      },
    },
    Popups: {
      EmailChangeOTP: {
        confirmEmailAddresses: 'Confirm Email Addresses',
        confirmEmail: 'Confirm Email Address',
        securityMessageVerified:
            'For security reasons, <strong>we have sent two confirmation codes</strong>: one to your old email address and one to the new one. <strong>Please enter them below to proceed</strong> with changing your email address.',
        securityMessage:
            'For security reasons, <strong>we have sent a confirmation code</strong> to your new email address. <strong>Please enter it below to proceed</strong>.',
        oldEmailCode: 'Old Email Code ({email})',
        newEmailCode: 'New Email Code ({new_email})',
        emailReceivedCode: 'Code received via email ({new_email})',
        confirm: 'Confirm',
        success:
            'Email address updated successfully. Please log in again to continue.',
        error: 'Error: {error}',
      },
      InstallApp: {
        appName: 'Dantebus Social',
        company: 'Dantebus Ltd',
        platformAndroid: 'Free - Google Play',
        platformIOS: 'Free - Apple Store',
        install: 'Install',
        open: 'Open',
      },
      LabelsFinder: {
        searchInterests: 'Search interests..',
        searchInterest: 'Search for an interest',
        searchEnglishOnly: 'Search only in English.',
        interest: 'Interest',
        publicReach: 'Public reach',
        selectInterest: 'Select an interest',
        addInterests_single: 'Add {count} interest',
        addInterests_multiple: 'Add {count} interests',
        confirmEmailAddresses: 'Confirm Email Addresses',
        confirmEmail: 'Confirm Email Address',
        securityMessageVerified:
            'For security reasons, <strong>we have sent two confirmation codes</strong>: one to your old email address and one to the new one. <strong>Please enter them below to proceed</strong> with changing your email address.',
        securityMessage:
            'For security reasons, <strong>we have sent a confirmation code</strong> to your new email address. <strong>Please enter it below to proceed</strong>.',
        oldEmailCode: 'Old Email Code ({email})',
        newEmailCode: 'New Email Code ({new_email})',
        emailReceivedCode: 'Code received via email ({new_email})',
        confirm: 'Confirm',
        success:
            'Email address updated successfully. Please log in again to continue.',
        error: 'Error: {error}',
      },
      ReportNode: {
        report: 'Report {nodeName}',
        motivation: 'Reason for reporting',
        cancel: 'Cancel',
        description: 'Describe the problem',
        optional: 'optional',
        send: 'Send',
        options: {
          annoys_me: "It bothers me",
          copyright_infringement: 'Copyright infringement',
          spam: "It's spam",
          inappropriate: "It shouldn't be on Dantebus",
        },
        error_select_motivation: 'Select a reason to continue',
        success_report: 'Report sent successfully',
        error_report: 'An unexpected error occurred. Please try again.',
      },
      SupportRequest: {
        reportProblem: 'Report a Problem',
        subject: 'Subject',
        describeProblem: 'Describe the problem',
        optional: 'optional',
        attentionMessage:
            'Attention! Our technical staff will respond to support requests directly to your email address ({email}).',
        send: 'Send',
        fillAllFields: 'Fill in all fields before proceeding.',
        successReport: 'Report sent successfully',
        errorReport: 'An unexpected error occurred. Please try again.',
      },
    },
    Portfolio: {
      AppArtistItem: {
        downloadApp: 'Download App',
        installApp: 'Install App',
        openApp: 'Open App',
        visitSite: 'Visit Site',
        freeGooglePlay: 'Free - Google Play',
        freeAppleStore: 'Free - Apple Store',
        cannotOpenLink: 'Cannot open the link',
      },
      DantebusEdizioniForm: {
        publishWithUs: 'Publish with Dantebus Edizioni',
        subtitle: 'Your Book or Art Catalog curated by professionals',
        submitManuscript: 'Submit your Manuscript or Art Catalog Now!',
        received: 'We have received your text or art catalog!',
        info: 'If the scientific committee deems it in line with our editorial policy, you will receive a publication proposal.',
        placeholders: {
          fullName: 'Full Name*',
          email: 'Email*',
          phone: 'Phone*',
          title: 'Title',
        },
        uploadText: 'Upload your text *',
        requiredFields: '* Required Fields',
        publishWithUsButton: 'Publish With Us',
        accepts: {
          part1: 'By submitting this form, you accept our ',
          privacyConditions: 'Privacy Policy',
        },
        toast: {
          verifyFields: 'Please check the required fields.',
          error:
              'An error has occurred. Please try uploading a smaller file or check the required fields.',
        },
      },
      MessageNoBookEdizioni: {
        title: "You haven't published any books with Dantebus yet",
        subtitle: 'Do you want to publish your book?',
        button: 'Publish with Dantebus Edizioni',
      },
      PortfolioHome: {
        app: 'Mobile Portfolios',
        libri: 'Books',
        videoPoetrie: 'Video Poems',
        gallerie3D: '3D Galleries',
      },
      VideoPoetryItem: {
        watch: 'Watch',
      },
      VirtualGalleryItem: {
        watch: 'Watch',
      },
    },
    Post: {
      PostItem: {
        viewSingular: 'View',
        viewPlural: 'Views',
        sponsored: 'Sponsored',
        subscribe: 'Subscribe',
        subscribeToGroup: 'Subscribe to Group',
        requestJoin: 'Request Membership',
        unsubscribe: 'Leave Group',
        watch: 'Watch',
        share: 'Share',
        shareWithFriends: 'Share with your friends',
        shareTitle: 'Watch post by {name}',
        shareText: 'Watch post by {name}',
        shareDialogTitle: 'Share with your friends',
        adCallToAction: 'Learn More',
      },
      PostList: {
        card: {
          scrollMessage:
              'Swipe from right to left to view other posts by {username}',
        },
        postListCard: {
          noMorePosts: 'There are no more posts',
        },
        infiniteScroll: {
          loadingText: 'Loading...',
        },
        modal: {
          header: 'This is a multiple post',
          message:
              'When an artist publishes multiple artworks in succession, they are grouped into a single post. Swipe from right to left to view all of them.',
          ok: 'Ok',
        },
      },
    },
    PostCreation: {
      FileGallery: {
        newWork: 'New Artwork',
        addWorks: 'Add Artworks',
        cancel: 'Cancel',
        select: 'Select',
        newText: 'New Text',
        uploadImage: 'Upload Image',
        library: 'Library',
        permissionToast: {
          message:
              'Grant permission to view all media and text files on your device',
          grant: 'Grant',
        },
        uploadPrompt: 'Click the buttons above to upload your artworks',
        cameraDenied:
            'Cannot use the feature because permission was denied. Please change permissions in your device settings.',
        permissionDialog: {
          denied: 'Permission was denied. To continue, go to Settings.',
          cancel: 'Cancel',
          openSettings: 'Open Settings',
        },
      },
      FilesManage: {
        title: 'Artworks',
        cancel: 'Cancel',
        next: 'Next',
        titleLabel: 'Artwork Title',
        caption: 'Caption',
        uploadPrompt: 'Click the buttons above to upload your artworks',
      },
      FilesUploadDesktop: {
        newWork: 'New Artwork',
        newText: 'New Text',
        select: 'Select',
        selectFromComputer: 'Select from computer',
        uploadFromLibrary: 'Upload from Library',
        dragFiles: 'Drag images, documents, or music here',
        selectFiles: 'Select images, documents, or music here',
        uploadError: 'Error uploading file {fileName}',
      },
      MediaSelector: {
        title: 'Your Library',
        loadingText: 'Loading...',
        cancel: 'Cancel',
        select: 'Select',
        alert: {
          header: 'Do you want to proceed?',
          message: 'You will not be able to recover this file.',
          buttons: {
            no: 'No',
            yes: 'Yes',
          },
        },
      },
      MediaTextEditor: {
        loading: 'Loading...',
      },
      MediaTextViewer: {
        noTitle: 'Untitled',
      },
      PostCreate: {
        alert: {
          header: 'Do you want to proceed?',
          message: 'All changes will be lost.',
          buttons: {
            no: 'No',
            yes: 'Yes',
          },
        },
      },
      PostHeadings: {
        title: 'Publication',
        cancel: 'Cancel',
        publish: 'Publish',
        postTitle: 'Post title',
        privacy: 'Privacy',
        privacyAll: 'Everyone',
        privacyFollowers: 'Followers only',
        privacyOnlyMe: 'Only me',
        privacyGroup: 'In a group',
        group: 'Group',
        adPost: 'Sponsored post',
        adUrl: 'Sponsored site URL',
        adText: 'CTA text',
        adCta: 'CTA button text',
        addLabel: 'Add',
        selectGroup: 'Select a group to proceed',
        error: 'An error occurred, please try again in a few minutes.',
      },
      TextContentEditor: {
        title: 'Caption',
        cancel: 'Cancel',
        save: 'Save',
        placeholder: 'Describe the content...',
      },
    },
    Search: {
      SearchResults: {
        placeholder: 'Search here...',
        noResult: 'No results found',
        users: 'Users',
        groups: 'Groups',
        loading: 'Loading...',
      },
    },
    Settings: {
      NotificationSettings: {
        title: 'Notification Settings',
        subtitle: 'Customize your notifications',
        notifications: 'Notifications ',
        enabled: 'Enabled',
        disabled: 'Disabled',
        active: 'Active',
        inactive: 'Inactive',
        saved: 'Saved',
        save: 'Save',
        singleNotificationTypes: {
          comment: 'Comments',
          reply: 'Replies',
          comment_tag: 'Tags or Mentions',
          subscription_request: 'Group subscription requests',
          new_post: 'New post published by a user you follow',
          new_subscription: 'New group subscribers',
          new_follower: 'New followers',
          like: 'Likes',
          group_request_accepted: 'Group membership request accepted',
          group_new_permission_admin: 'You became a Group Admin',
          group_new_permission_mod: 'You became a Group Moderator',
          group_unban: 'You are no longer banned from a group',
          group_ban: 'You have been banned from a group',
        },
      },
    },
    User: {
      ToggleFollowButton: {
        follow: 'Follow',
        unfollow: 'Unfollow',
      },
      DeleteAccountConfirm: {
        title: 'Confirm Account Deletion',
        instruction:
            'Enter the 6-digit code we sent to your email address to confirm the permanent deletion of your account.',
        warningTitle: 'Warning',
        warningMessage:
            'All your posts will be permanently deleted, and you will only be able to access Dantebus again by creating a new account.',
        codePlaceholder: '6-digit code',
        confirmButton: 'Confirm Deletion',
        invalidCode: 'Invalid code',
        error: 'An error occurred. Please try again later.',
      },
      FollowersFollowing: {
        followers: 'Followers',
        following: 'Following',
        loading: 'Loading...',
      },
      ImageUpload: {
        updateAvatar: 'Update avatar',
        updateCover: 'Update cover',
        dragHere: 'Drag an image here',
        selectImage: 'Select an image',
        cancel: 'Cancel',
        save: 'Save',
        invalidImage: 'You did not select an image',
      },
      NativeImageCropper: {
        updateAvatar: 'Update avatar',
        updateCover: 'Update cover',
        save: 'Save',
      },
      PrintProfile: {
        title: 'Profile',
        yes: 'Yes',
        no: 'No',
      },
      SuggestionsHorizontal: {
        title: 'Suggested for you...',
        followers: 'Followers',
        following: 'Following',
      },
      UpdateEmail: {
        currentEmail: 'Current Email',
        newEmail: 'New Email Address',
        confirmNewEmail: 'Confirm New Email Address',
        verify: 'Verify',
        enterAndConfirmEmail:
            'Enter and confirm the new email address to proceed',
        emailMismatch: 'The email addresses do not match.',
        invalidEmail: 'The provided email address is not valid.',
        sameAsCurrentEmail:
            'The provided email address is the same as the current one, unable to proceed.',
      },
      UpdatePassword: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        save: 'Save',
        successMessage: 'Password updated successfully!',
      },
      UpdateProfile: {
        title: 'Your Profile',
        updateMoreInfo: 'Update other information',
        save: 'Save',
        successMessage: 'Profile updated successfully',
        errorMessage: 'An error occurred during saving',
        fields: {
          name: 'Username',
          phone: 'Phone number',
          first_name: 'First name',
          last_name: 'Last name',
          birthday: 'Date of birth',
          small_biography: 'Short biography',
          biography: 'Full biography',
          country: 'Country',
          city: 'City',
          is_artist: 'Are you an artist?',
          gender: 'Gender',
          facebook_profile_url: 'Facebook profile',
          twitter_profile_url: 'X profile',
          dribbble_profile_url: 'Dribbble profile',
          youtube_url: 'YouTube channel',
          whatsapp: 'WhatsApp phone number',
          other_details: 'Other details about you',
        },
      },
      UsersLargeList: {
        followers: 'Followers',
        following: 'Following',
      },
    },
    BoxNovita: {
      button: 'I Understood',
    },
    ReadMoreText: {
      readMore: 'Read More...',
      readLess: 'Read Less',
    },
    ServerMaintenance: {
      title: 'A new version of our Social is coming soon!',
      subtitle: 'Dantebus Social is being updated!',
      description:
          'In just a few hours, a new version of the first Social Network for Artists will be available.',
    },
    SuggestUserIntro: {
      title: 'Suggestions for you',
      description: {
        part1:
            "It looks like you're not following any artists on Dantebus yet! Here are some suggestions for you.",
        part2: 'Select {count} artists to proceed',
      },
    },
    TagInterest: {
      title: 'Select Your Artistic Interests',
      selectInterest: 'Select one or more interests',
      selectedTitle: 'You are interested in...',
      showMore: 'Show more',
    },
  },
  layouts: {
    Authentication: {
      ForgotPasswordDesktop: {
        title: 'Forgot Password?',
        instructions: 'Enter your email address to begin the recovery process',
        emailPlaceholder: 'Your account email',
        nextButton: 'Next',
        backButton: 'Back',
        emailSentMessage:
            'An email has been sent to you with a link to reset your password.',
        emailFailedMessage:
            'Password recovery is not possible at this time. Please try again later.',
      },
      ForgotPasswordMobile: {
        title: 'Forgot Password?',
        instructions: 'Enter your email address to begin the recovery process',
        emailPlaceholder: 'Your account email',
        nextButton: 'Next',
        backButton: 'Back',
        emailSentMessage:
            'An email has been sent to you with a link to reset your password.',
        emailFailedMessage:
            'Password recovery is not possible at this time. Please try again later.',
      },
      LoginDesktop: {
        welcomeBack: 'Welcome back!',
        instructions: 'Enter your email address and password to proceed',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        forgotPassword: 'Forgot Password?',
        loginButton: 'Log In',
        socialLogin: 'Log in with',
        registerButton: 'Sign up with email',
      },
      LoginMobile: {
        welcomeBack: 'Welcome back!',
        instructions: 'Enter your email address and password to proceed',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        forgotPassword: 'Forgot Password?',
        loginButton: 'Log In',
        socialLogin: 'Log in with',
        registerButton: 'Sign up with email',
      },
      RegistrationDesktop: {
        createAccount: 'Create an account',
        instructions:
            'Enter your details to join the Social Network for artists',
        usernamePlaceholder: 'Username',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        passwordWeak: 'The password is too weak',
        passwordRequirement1: 'Minimum 8 characters.',
        passwordRequirement2: 'At least one UPPERCASE letter.',
        passwordRequirement3: 'At least one lowercase letter.',
        passwordRequirement4: 'At least one number.',
        passwordRequirement5: 'At least one special character.',
        signupButton: 'Sign Up',
        termsAndConditions:
            'By clicking "Sign Up", you agree to our <a href="https://dantebus.com/note-legali" target="_blank">Terms</a> and confirm that you have read our <a href="https://www.iubenda.com/privacy-policy/60273184" target="_blank">Privacy Policy</a>, including the <a href="https://www.iubenda.com/privacy-policy/60273184/cookie-policy" target="_blank">Cookie Policy</a>.',
        signupWith: 'Sign up with',
        loginButton: 'Log In',
      },
      RegistrationMobile: {
        createAccount: 'Create an account',
        instructions:
            'Enter your details to join the Social Network for artists',
        usernamePlaceholder: 'Username',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        passwordWeak: 'The password is too weak',
        passwordRequirement1: 'Minimum 8 characters.',
        passwordRequirement2: 'At least one UPPERCASE letter.',
        passwordRequirement3: 'At least one lowercase letter.',
        passwordRequirement4: 'At least one number.',
        passwordRequirement5: 'At least one special character.',
        signupButton: 'Sign Up',
        termsAndConditions:
            'By clicking "Sign Up", you agree to our <a href="https://dantebus.com/note-legali" target="_blank">Terms</a> and confirm that you have read our <a href="https://www.iubenda.com/privacy-policy/60273184" target="_blank">Privacy Policy</a>, including the <a href="https://www.iubenda.com/privacy-policy/60273184/cookie-policy" target="_blank">Cookie Policy</a>.',
        signupWith: 'Sign up with',
        loginButton: 'Log In',
      },
    },
    LeftBar: {
      following: 'Following',
      forYou: 'For You',
    },
    TopBar: {
      searchPlaceholder: 'Search here...',
      newPost: 'New Post',
      myWall: 'My Dashboard',
      portfolio: 'Portfolio',
      new: 'New',
      myProfile: 'My Profile',
      myGroups: 'My Groups',
      myFavorites: 'My Favorites',
      reportProblem: 'Report a Problem',
      logout: 'Logout',
    },
    BottomBar: {
      myWall: 'My Dashboard',
      portfolio: 'Portfolio',
      myProfile: 'My Profile',
      myGroups: 'My Groups',
      reportProblem: 'Report a Problem',
      logout: 'Logout',
    },
    HomeDesktop: {
      subtitle: 'Join the social network for Artists!',
      loginButton: 'Login',
      registerButton: 'Sign up',
    },
    HomeMobile: {
      subtitle: 'Join the social network for Artists!',
      loginButton: 'Login',
      registerButton: 'Sign up',
      versionLabel: 'Version:',
    },
    inputs: {
      PrivacySelector: {
        cancelText: 'Cancel',
        all: 'Everyone',
        following: 'People I follow',
        onlyMe: 'Only me',
      },
    },
    Intro: {
      welcomeMessage:
          'Welcome to Dantebus, the Italian social network entirely dedicated to Art!',
      initialConfig: 'Initial Configuration',
      completeProfile: 'Set up and complete your Profile',
      selectCover: 'Select Cover',
      changeCoverImage: 'Change Cover Image',
      back: 'Back',
      exit: 'Exit',
      next: 'Next',
      finish: 'Finish',
    },
    LoggedInLayout: {
      newMessageFrom: 'You received a message from',
      view: 'View',
      ok: 'OK',
    },
  },
  mixins: {
    appInterface: {
      loading: 'Loading...',
      wantLogout: 'Do you want to log out?',
      wantClose: 'Do you want to close the app?',
      NO: 'NO',
      YES: 'YES',
    },
    chatInterface: {
      confirmationHeader: 'Confirm?',
      confirmationMessage:
          'The conversation will be deleted for both participants.',
      cancelButton: 'Cancel',
      deleteButton: 'Delete',
      deletingMessage: 'Deleting...',
    },
    docEditor: {
      write: 'Write...',
    },
    notificationInterface: {
      newFollower: 'User <b>{fromUserName}</b> started following you',
      subscriptionRequest:
          'User <b>{fromUserName}</b> requested to join the group <b>{subjectName}</b>',
      comment: 'User <b>{fromUserName}</b> commented on your {name}',
      like: 'User <b>{fromUserName}</b> liked your {name}',
      newPost: 'User <b>{fromUserName}</b> published a new post',
      commentTag: 'User <b>{fromUserName}</b> tagged you in a comment {name}',
      newSubscription:
          'User <b>{fromUserName}</b> joined the group <b>{subjectName}</b>',
      reply: 'User <b>{fromUserName}</b> replied to your {name}',
      groupRequestAccepted:
          'Your request to join the group <b>{subjectName}</b> has been accepted',
      groupNewPermissionAdmin:
          'You are now an admin of the group <b>{subjectName}</b>',
      groupNewPermissionMod:
          'You are now a moderator of the group <b>{subjectName}</b>',
      groupUnban: 'You can access the group <b>{subjectName}</b> again',
      groupBan:
          'You have been banned from the group <b>{subjectName}</b> and can no longer access it.',
      post: 'post',
      group: 'group',
      comment1: 'comment',
    },
  },
  pages: {
    ChatList: {
      chat: 'Chat',
      newMessage: 'New message',
      writing: 'Writing...',
      attachment: 'Attachment',
      attachments: 'Attachments',
      chatDeleted: 'Chat with {username} successfully deleted',
      userEliminated: 'User Deleted',
    },
    SingleChat: {
      selectUser: 'Select a user to start!',
      or: 'or',
      startChat: 'Start a chat now!',
      writing: 'is writing...',
      writeMessage: 'Write a message...',
      blocked: 'Conversation Blocked',
      chatDeleted: 'Chat deleted successfully',
      chatDeletedWarning: 'The chat has been deleted',
      sendError: 'Unable to send message: ',
      unexpectedError:
          'Unable to send message: Unexpected error. Try again later.',
    },
    DantebusEdizioni: {
      FormManoscritto: {
        whyChoose: 'Why choose Dantebus Edizioni?',
        features: {
          0: {
            title: 'Detailed foreword and critique',
            description:
                'We offer a detailed foreword and critique written by our Scientific Committee that, through an in-depth analysis of the artwork, puts it in the spotlight and presents the main features of your poetics, its philosophical implications, and the themes you have addressed.',
          },
          1: {
            title: 'Professional editing',
            description:
                'Our team of expert editors meticulously works on your artworks, manuscript or art catalog (photography or painting) to perfect its style, structure, and content, ensuring the highest text quality.',
          },
          2: {
            title: 'Accurate layout',
            description:
                'We take care of every detail of the layout to ensure a visually appealing presentation and a smooth reading experience, respecting the professional standards of the publishing industry.',
          },
          3: {
            title: 'Cover created by a digital artist',
            description:
                "Our talented digital artist creates unique and captivating covers, designed to capture the essence of your artwork and attract readers' attention.",
          },
          4: {
            title: 'ISBN and global distribution',
            description:
                'We assign a unique ISBN code to your book and distribute it in e-book format on platforms such as Mondadori, Feltrinelli, IBS, Amazon, and Apple. Additionally, the physical version will be available in our art galleries in Rome (Via Margutta 38) and Florence (Via dello Studio 22), increasing the visibility of your artwork.',
          },
          5: {
            title: 'Sale in our Libraries/Art Galleries',
            description:
                'Your book will be available in our art galleries, located in the historic center of Rome (Via Margutta 38) and Florence (Via dello Studio 22, near the Duomo), offering a prestigious store for your artwork.',
          },
        },
      },
    },
    ForgotPassword: {
      UpdatePassword: {
        newPasswordTitle: 'New Password',
        validCodeMessage: 'Valid code, enter your new password here',
        newPasswordPlaceholder: 'New Password',
        confirmPasswordPlaceholder: 'Confirm Password',
        updateButton: 'Update Password',
        passwordRules: {
          title: 'Your Password must contain:',
          rule1: '- At least 8 characters;',
          rule2: '- At least one UPPERCASE letter;',
        },
        successMessage: 'Password Updated successfully',
        errorMessage:
            'Password could not be updated. Please try again later.',
        weakPasswordMessage:
            'Weak Password..\nInclude at least one UPPERCASE letter,\none lowercase letter,\none number,\none special character',
        passwordMismatchMessage: 'Passwords do not match',
      },
      ValidateCode: {
        verifyCodeTitle: 'Verify Code',
        successEmailMessage:
            'An email with the code has been successfully sent. Enter here to continue.',
        backButton: 'Back',
      },
    },
    Group: {
      GroupHeader: {
        changeCoverImageButton: 'Change Cover Image',
        groupPrivacy: {
          public: 'Public',
          private: 'Private',
        },
        members: 'Members',
        subscribePublic: 'Join Group',
        subscribePrivate: 'Request Membership',
        unsubscribe: 'Leave Group',
        editGroupButton: 'Edit Group',
        dashboard: 'Dashboard',
        membersLabel: 'Members',
        privateGroupMessage:
            'The group is private, request membership to join the group and view posts.',
        shareTitle: 'Check out the group {name}',
        shareText: 'Check out the group of {name}',
        shareDialogTitle: 'Share with your friends',
      },
      MembersGroup: {
        members: 'Members',
        requests: 'Requests',
        banned: 'Banned',
      },
      PostGroup: {
        postYourWork:
            'Hello {name}, click here to post your artwork in this group',
        bannedMessage: 'You have been banned from this group.',
      },
    },
    Profile: {
      AdvertiserProfile: {
        headings: {
          campaign: 'Campaign',
          status: 'Status',
          dailyPublications: 'Daily Publications',
          impressions: 'Impressions',
          clicks: 'Clicks',
        },
        placeholders: {
          dailyPublications: 'Daily Publications',
        },
        loading: 'Loading...',
      },
      InfoProfile: {
        updatePassword: 'Update Password',
        updateEmail: 'Update Email Address',
        linkSocialAccounts: 'Social Media Accounts',
        linkSocialDescription:
            'Link your Google, Facebook{apple} accounts to speed up your login on Dantebus Social. No posts will be published on your behalf on your social profiles.',
        andApple: ' and Apple',
        manageAccount: 'Account Management',
        deleteAccount: 'Delete my account',
        downloadInformation: 'Download my data',
        logout: 'Logout',
        version: 'Ver: {version}',
        downloadInfoMessage:
            'You have requested to download your data. Within a few minutes, an email will be sent to your address containing a link to download it.',
        accountDeletionMessage:
            'Do you want to delete your account? All your posts will be permanently deleted, you will only be able to access Dantebus again by creating a new account. Do you want to proceed?',
        no: 'NO',
        yes: 'YES',
        ok: 'OK',
        language: 'Language',
      },
      LinkedAccount: {
        facebook: 'Facebook',
        google: 'Google',
        apple: 'Apple',
        link: 'Link',
        linked: 'Linked',
        unlink: 'Logout',
      },
      ProfileHeader: {
        buttons: {
          changeCoverImage: 'Change cover image',
          writeMessage: 'Write a message',
          unblock: 'Unblock',
          block: 'Block',
          shadowBan: 'Shadow ban',
          banDefinitive: 'Definitive ban',
          share: 'Share',
          banUser: 'Ban User',
          report: 'Report',
        },
        labels: {
          followers: 'Followers',
          following: 'Following',
          posts: 'Posts',
          comments: 'Comments',
          followersCount: 'Followers',
          followingCount: 'Following',
          sections: {
            feed: 'Feed',
            portfolio: 'Portfolio',
            profile: 'Profile',
            groups: 'Groups',
            bookmarked: 'Bookmarks',
            advertising: 'Advertising',
            adv: 'Adv',
          },
        },
        messages: {
          accountFollowedBy: 'Account followed by: ',
          blockedUser: 'You have blocked this user. Click above to unblock',
        },
        actionSheet: {
          header: 'Options',
        },
        share: {
          title: "Check out {name}'s profile",
          text: "Check out {name}'s profile",
          dialogTitle: 'Share with your friends',
        },
      },
    },
    ChatView: {
      noActiveChats: 'You have no active chats',
      startChatNow: 'Start a chat now!',
    },
    MobileSearch: {
      searchTitle: 'Search on Dantebus Social',
    },
    ProfilePage: {
      profileUnavailable: 'Profile not available',
      ok: 'OK',
    },
    Wall: {
      suggestionsForYou: 'Suggestions for you',
      suggestionText1:
          'It seems you are not following any artists on Dantebus yet! Here are some suggestions for you.',
      suggestionText2: 'Select {count} artists to proceed',
      newPosts: 'New posts',
    },
  },
  App: {
    emailNotVerified: 'Your email address is not verified',
    retryInSeconds: 'You can retry in {count} seconds',
    sendVerificationEmail: 'Send verification email',
    verifyMyEmail: 'Verify my email',
    profileUnavailable: 'Profile not available',
    ok: 'OK',
    verificationEmailSent: 'Verification email sent successfully.',
    errorSendingVerificationEmail:
        'An error occurred, please try again in a few minutes.',
    appName: 'Dantebus',
    newUpdateAvailable: 'A new update is available.<br>{changelog}',
    ignore: 'Ignore',
    update: 'Update',
    updating: 'Updating...',
  },
};
