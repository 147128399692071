import {
  alertController,
  loadingController,
  toastController,
  isPlatform,
} from '@ionic/vue';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PushNotifications } from '@capacitor/push-notifications';
import { Storage } from '@ionic/storage';
const store = new Storage();
const defaultTitle = 'Dantebus Social';
import firebaseErrors from '../boot/firebaseAuthMessages.json';
import { v4 as uuidv4 } from 'uuid';
import eventBus from '../helpers/eventbus';

export default {
  data: () => ({
    //Minimo 8 Caratteri, uno maiuscolo, uno minuscolo
    strongRegex: new RegExp('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d].{8,}$'),
    loadingInterface: null,
    genericLoading: false,
    numero_followers: 5, //numero minimo di followers
  }),
  computed: {
    itemsGroup() {
      return this.$store.getters['posts/items_grouped'];
    },
    currentUser() {
      return this.$store.state.user;
    },
    currentUserCounts() {
      return this.$store.state.user.counts;
    },
    uuid() {
      return uuidv4();
    },
    user() {
      return this.$store.getters['user/user'];
    },
    claims() {
      return this.$store.getters['user/claims'];
    },
    internalNotifications() {
      return this.claims && this.claims.internalNotifications
        ? this.claims.internalNotifications
        : undefined;
    },
    internalNotificationsData() {
      return this.$store.getters['user/internalNotificationsData'];
    },
    filteredNotifications() {
      return this.internalNotificationsData
        ? Object.keys(this.internalNotificationsData).filter(
            (item) =>
              this.internalNotifications === undefined ||
              this.internalNotifications[item] !== false
          )
        : null;
    },
    userNode() {
      return this.$store.getters['user/node'];
    },
    user_has_active_app() {
      return this.$store.getters['user/user_has_active_app'];
    },
    chatUnread() {
      return this.$store.getters['user/chatUnread'];
    },
    isFirstLogin() {
      console.log('isFirstLogin', this.$store.getters['user/isFirstLogin']);
      return this.$store.getters['user/isFirstLogin'];
    },
    followedUsersIds() {
      return this.$store.getters['user/followedUsersIds'];
    },
    isMobile() {
      return isPlatform('mobile', 'mobileweb');
    },
    isMobileAndroid() {
      return isPlatform('android');
    },
    isMobileiOS() {
      return isPlatform('ios');
    },
    isDesktop() {
      return isPlatform('desktop');
    },
    isDesktopResolution() {
      return isPlatform('desktop') || window.screen.availWidth > 768;
    },
    isNative() {
      return isPlatform('capacitor');
    },
    isAndroid() {
      return this.isNative && isPlatform('android');
    },
    isNotAndroid() {
      return !(this.isNative && isPlatform('android'));
    },
    isIos() {
      return this.isNative && isPlatform('ios');
    },
    isWebIOS() {
      const browserInfo = navigator.userAgent.toLowerCase();

      if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
        return true;
      }
      if (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform)
      ) {
        return true;
      }
      return false;
    },
    bartitle() {
      return this.unReadedNotification !== ''
        ? '(' + this.unReadedNotification + ') | ' + defaultTitle
        : defaultTitle;
    },
    unReadedNotification() {
      if (
        this.$store.getters['user/counts'] !== null &&
        this.$store.getters['user/counts'].unreaded_notifications_count
      ) {
        const count =
          this.$store.getters['user/counts'].unreaded_notifications_count !== 0
            ? this.$store.getters['user/counts'].unreaded_notifications_count
            : '';

        return count;
      } else {
        return '';
      }
    },
    notificationSettings() {
      return this.$store.getters['user/notificationSettings'];
    },
    onMobileChat() {
      return (
        (this.isNative || this.isMobile) &&
        (this.$route.name === 'singleChat' ||
          this.$route.name === 'chatList' ||
          this.$route.name === 'chat')
      );
    },
    onMobileCurrentChat() {
      return (
        (this.isNative || this.isMobile) &&
        (this.$route.name === 'singleChat' || this.$route.name === 'chatList')
      );
    },
    onMobileSingleChat() {
      return (
        (this.isNative || this.isMobile) && this.$route.name === 'singleChat'
      );
    },
    new_posts: {
      get() {
        return this.$store.getters['posts/new_posts'];
      },
      set(value) {
        this.$store.commit('posts/setNewPosts', value);
      },
    },
    notificationNativeEnabled: {
      get() {
        return this.$store.getters['user/notificationNativeEnabled'];
      },
      set(value) {
        this.$store.commit('user/updateNotificationNativeEnabled', value);
      },
    },
  },
  setup: async () => {
    //await this.setNavBarColor('#ffffff');
    const loadingInterface = await loadingController.create({
      message: this.$t('mixins.appInterface.loading'),
      cssClass: 'alert-css-class',
      mode: 'md',
    });

    return {
      loadingInterface,
    };
  },
  watch: {
    bartitle(value) {
      document.title = value;
    },
    genericLoading(value) {
      if (!this.loadingInterface) return;
      if (value === true) {
        this.loadingInterface.present();
      } else {
        this.loadingInterface.dismiss();
      }
    },
    '$store.state.user.user'(value) {
      if (
        !value &&
        this.route !== undefined &&
        this.route.meta.authRequired === true
      ) {
        window.location.href = '/';
      }
    },
    async $route(value) {
      eventBus().emitter.emit('current-route', value.fullPath);
      if (this.isNative) {
        if (value.name === 'home') {
          await this.setNavBarColor('#BE202E');
        } else {
          await this.setNavBarColor('#ffffff');
        }
      }
    },
  },
  methods: {
    async checkIfIsAndroid13() {
      const info = await Device.getInfo();
      try {
        const os = parseInt(info.osVersion);
        return os >= 12;
      } catch (error) {
        return false;
      }
    },
    //Android: sistema per capire se esiste l'app installata
    async checkIfAppAndroidInstalled() {
      try {
        const relatedApps = await navigator.getInstalledRelatedApps();
        console.log(relatedApps);
        if (relatedApps) {
          if (relatedApps.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    async authenticate() {
      this.loading = true;
      const response = await this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password,
      });
      if (response.status === false) {
        const error_message = firebaseErrors[this.$i18n.locale][
          response.error_code
        ]
          ? firebaseErrors[this.$i18n.locale][response.error_code]
          : firebaseErrors.else.replace('%error_code%', response.error_code);
        await this.showToast(
          error_message.replace('%error_code%', response.error_code)
        );
      } else {
        const stored_auth = window.localStorage.getItem('authRedirect');
        if (stored_auth !== null) {
          window.localStorage.removeItem('authRedirect');
          window.location.href = stored_auth;
        } else {
          this.pushTo('/wall');
        }
      }
      this.loading = false;
    },
    async signup() {
      this.loading = true;
      const response = await this.$store.dispatch('user/register', {
        email: this.email,
        password: this.password,
        name: this.name,
      });
      if (response.status === false) {
        const error_message = firebaseErrors[this.$i18n.locale][
          response.error_code
        ]
          ? firebaseErrors[this.$i18n.locale][response.error_code]
          : firebaseErrors.else.replace('%error_code%', response.error_code);
        await this.showToast(error_message);
      } else {
        const stored_auth = window.localStorage.getItem('authRedirect');
        if (stored_auth !== null) {
          window.localStorage.removeItem('authRedirect');
          window.location.href = stored_auth;
        } else {
          this.pushTo('/wall');
        }
      }
      this.loading = false;
    },
    async loadSuggestions() {
      await this.$store.dispatch('user/fetchSuggestions');
    },
    replaceNav(to) {
      this.$router.push(to);
    },
    async showToast(message, options) {
      if (!options) options = {};

      const toast = await toastController.create({
        message,
        position: this.isDesktop ? 'bottom' : 'top',
        duration: 3000,
        color: 'danger',
        buttons: [
          {
            text: 'OK',
            side: 'end',
            role: 'cancel',
          },
        ],
        ...options,
      });
      toast.present();
    },
    async showDialog(message) {
      const alert = await alertController.create({
        header: 'Dantebus',
        message: message,
        buttons: ['OK'],
        cssClass: 'alert-css-class',
        mode: 'md',
      });
      await alert.present();
    },
    async showCustomDialog(message, buttons) {
      const alert = await alertController.create({
        header: 'Dantebus',
        message: message,
        buttons: buttons,
        cssClass: 'alert-css-class',
        mode: 'md',
      });
      await alert.present();
    },
    validatePassword(value) {
      return this.strongRegex.test(value);
    },
    pushTo(path) {
      this.$router.push(path);
    },
    async logout(force) {
      if (force === true) {
        await this.executeLogout();
        return;
      }

      const alert = await alertController.create({
        header: 'Dantebus',
        message: this.$t('mixins.appInterface.wantLogout'),
        buttons: [
          {
            text: this.$t('mixins.appInterface.NO'),
            id: 'cancel',
            cssClass: 'secondaryAlertButton',
            handler: () => {
              alert.dismiss();
            },
          },
          {
            text: this.$t('mixins.appInterface.YES'),
            role: 'confirm-button',
            id: 'cancel-button',
            handler: async () => {
              await this.executeLogout();
            },
          },
        ],
        cssClass: 'alert-css-class',
        mode: 'md',
      });
      await alert.present();
    },
    async executeLogout() {
      await this.$store.dispatch('user/logout');
      try {
        //Rimuovo il token
        await this.removeFCMToken();
      } catch (error) {
        console.log(error);
      }
      window.location.href = '/';
    },
    async exitApp() {
      const alert = await alertController.create({
        header: 'Dantebus',
        message: this.$t('mixins.appInterface.wantClose'),
        buttons: [
          {
            text: this.$t('mixins.appInterface.NO'),
            id: 'cancel',
            cssClass: 'secondaryAlertButton',

            handler: () => {
              alert.dismiss();
            },
          },
          {
            text: this.$t('mixins.appInterface.YES'),
            role: 'confirm-button',
            id: 'cancel-button',
            handler: async () => {
              App.exitApp();
            },
          },
        ],
        cssClass: 'alert-css-class',
        mode: 'md',
      });
      const isOpened = await alertController.getTop();
      console.log(isOpened);
      if (!isOpened) {
        console.log('APP', 'mostro dialog');
        await alert.present();
      }
    },
    async checkTokenFirebase() {
      this.openPopUpNotification = false;
      if (isSupported()) {
        const messaging = getMessaging();
        const currentToken = await getToken(messaging, {
          vapidKey:
            'BGppnO_w6lWoK6cp-SmtFCrCXp-3Z3e4IOGOvN9UPBpcc7I2CPZdrxbSPTsoZfBjDIsok_IfjTuRTLiEFJuz7QQ',
        });
        if (currentToken) {
          this.storeFCMToken(currentToken);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      } else {
        console.log('Browser non supportato');
      }
    },
    async storeFCMToken(currentToken) {
      const info = await Device.getInfo();
      const { identifier } = await Device.getId();
      console.log('Storicizzo Token', identifier, currentToken);
      await store.create();
      const token = await store.get('fcm_token');
      if (token !== null && token === currentToken) return;
      let platform = '';
      if (info.operatingSystem === 'android' && info.platform === 'android') {
        platform = 'android';
      } else if (info.operatingSystem === 'ios' && info.platform === 'ios') {
        platform = 'ios';
      } else if (info.platform === 'web' && isPlatform('desktop')) {
        platform = 'desktop';
      } else if (info.platform === 'web' && isPlatform('mobileweb')) {
        platform = 'mobileweb';
      }
      console.log('Storicizzo Token', identifier, info.model, currentToken);

      //TODO controllare se il token firebase è diverso da quello storicizzato
      const result = await this.$store.dispatch('user/archiviaToken', {
        token: currentToken,
        platform,
        uuid: identifier,
      });
      if (result.status) {
        store.set('fcm_token', currentToken);
      }
    },
    async removeFCMToken() {
      const { uuid } = await Device.getId();
      const { platform } = await Device.getInfo();

      //TODO controllare se il token firebase è diverso da quello storicizzato
      const result = await this.$store.dispatch('user/deleteToken', {
        uuid,
        platform,
      });
      console.log('removeFCMToken', result);
    },
    scrollTop() {
      const wall_content = document.querySelector('#wall_content');
      if (wall_content && wall_content.shadowRoot) {
        wall_content.shadowRoot
          .querySelector('main')
          .scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    //estrae dal testo link e genera html
    extractLinkFromText(text) {
      var content = text;
      if (content !== undefined) {
        let regex =
          /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
        let match = content.match(regex);
        if (match) {
          match.map((link) => {
            if (link.indexOf('http') >= 0) {
              content = content.replace(
                link,
                `<a href="${link}" target="_blank" >${link}</a>`
              );
            }
          });
          return content;
        }
        return content;
      }
      return content;
    },
    //Setto colore barra di navigazione e status bar
    async setNavBarColor(hex) {
      if (isPlatform('capacitor')) {
        if (hex.toLowerCase() === '#ffffff') {
          await StatusBar.setStyle({ style: Style.Light });
        } else {
          await StatusBar.setStyle({ style: Style.Dark });
        }
        if (isPlatform('android')) {
          await NavigationBar.setColor({
            color: hex,
            darkButtons: hex.toLowerCase() === '#ffffff',
          });
        }
      }
    },
    async addNotificationListeners() {
      try {
        await store.create();
        await PushNotifications.addListener('registration', async (token) => {
          console.log('Registration token: ', token.value);
          this.storeFCMToken(token.value);
          await store.set('sent_fcm_token_at', new Date().getTime());
        });

        await PushNotifications.addListener('registrationError', (err) => {
          console.error('Registration error: ', err.error);
          throw new Error('Registration error: ', err.error);
        });

        await PushNotifications.addListener(
          'pushNotificationReceived',
          async (payload) => {
            const body = JSON.parse(payload.data.data);
            console.log(body);
            /*const result = await this.$store.dispatch(
              "userNotification/newPushNotification",
              { notification: body.notification }
            );
            console.log(result);*/
          }
        );

        await PushNotifications.addListener(
          'pushNotificationActionPerformed',
          async (item) => {
            console.log(
              'Push notification action performed',
              item.notification.data
            );
            if (isPlatform('ios')) {
              PushNotifications.removeDeliveredNotifications();
            }
            const body = JSON.parse(item.notification.data.data);
            const { notification } = body;
            console.log(
              'Push notification action performed',
              notification.notification.properties.action,
              notification
            );
            //Pusho prima in chat e poi all'utente
            if (
              notification.notification.properties.action === 'chat_message'
            ) {
              await this.$router.push('/chat');
              await this.$router.push(
                this.getRouterBack(
                  notification.notification.properties.action,
                  notification
                )
              );
            } else {
              await this.$router.push(
                this.getRouterBack(
                  notification.notification.properties.action,
                  notification
                )
              );
            }
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async removeNotificatonListener() {
      await PushNotifications.removeAllListeners();
    },
    getRouterBack(action, item) {
      let rottaChat = '';
      let rottaPost = '';
      let rottaGruppi = '';
      let rottaCommenti = '';
      if (item.subject !== null) {
        rottaPost = '/post/' + item.subject.identity;
        rottaGruppi = '/group/' + item.subject.identity;
        if (item.subject.node === 'CommentNode') {
          rottaCommenti =
            '/post/' +
            item.subject.relationship.identity +
            '/' +
            item.subject.identity +
            '?t=' +
            new Date().getTime();
        } else {
          rottaCommenti = rottaPost;
        }
      }
      const rottaProfilo = '/profile/' + item.from_user.identity;

      if (action === 'chat_message') {
        rottaChat = '/chat/' + item.from_user.id;
      }

      const classes = {
        subscription_request: rottaGruppi,
        comment: rottaCommenti,
        like: rottaCommenti,
        new_post: rottaPost,
        comment_tag: rottaCommenti,
        new_subscription: rottaGruppi,
        new_follower: rottaProfilo,
        group_request_accepted: rottaGruppi,
        group_new_permission_admin: rottaGruppi,
        group_new_permission_mod: rottaGruppi,
        group_unban: rottaGruppi,
        group_ban: rottaGruppi,
        reply: rottaCommenti,
        chat_message: rottaChat,
      };
      console.log(classes);
      if (action in classes) return classes[action];
    },
    async getItemFromStorage(key) {
      const versione = await store.get(key);
      return versione;
    },
  },
};
